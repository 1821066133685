const gateways = {
  path: "gateways",
  name: "fundraising.settings.gateways",
  component: () =>
    import("/resources/js/components/fundraising/settings/gateways/Index.vue"),
  props: true,
  children: [
    {
      path: "list",
      name: "fundraising.settings.gateways.list",
      component: () =>
        import(
          "/resources/js/components/fundraising/settings/gateways/List.vue"
        ),
      props: true,
    },
    {
      path: ":id",
      props: true,
      component: () =>
        import(
          "/resources/js/components/fundraising/settings/gateways/Item.vue"
        ),
      children: [
        {
          path: "delete",
          name: "fundraising.settings.gateways.delete",
          props: true,
          component: () =>
            import(
              "/resources/js/components/fundraising/settings/gateways/Delete.vue"
            ),
        },
        {
          path: "show",
          name: "fundraising.settings.gateways.show",
          props: true,
          component: () =>
            import(
              "/resources/js/components/fundraising/settings/gateways/Show.vue"
            ),
        },
      ],
    },
  ],
};
export default gateways;
