<template>
    <div v-if="items">
        <v-autocomplete
            cache-items
            :disabled="disabled"
            :items="items"
            :hint="hint"
            :label="label"
            :dense="dense"
            :search-input.sync="search"
            :item-text="textField"
            :item-value="valueField"
            placeholder="Начните печатать для поиска"
            
            clearable
            v-model="item"
            :loading="loading"
            :rules="rules"
        />

    </div>
</template>
<script>
import {computed, onMounted, ref, watch, toRefs} from "vue";

export default {
    props: {
        value: {
            default: null,
        },
        rules: {
            default: undefined,
        },
        api: {
            default: null,
        },
        disabled: {
            default: false,
        },
        hint: {
            default: '',
        },
        label: {
            default: '',
        },
        dense: {
            default: false,
        },
        valueField: {
            default: 'id',
        },
        textField: {
            default: 'title',
        },
        method: {
            default: 'get'
        },
    },
    setup(props, {root, emit}) {
        const items = ref(null);
        const search = ref('');
        const loading = ref(false);
        const item = ref(null);
        const value = ref(props.value);
        const fetch = async (s = '') => {
            loading.value = true;
            s = s == null ? '' : s;
            return new Promise((resolve, reject) => {
                if (!props.api)
                    reject({message: 'не задан api'});
                axios[props.method](`${props.api}?search=${s}`).then(resp => {
                    items.value = resp.data;
                    resolve(items.value);
                }).catch(err => reject(err))
            }).finally(() => (loading.value = false));
        }
        watch(search, (current) => {
            fetch(current);
        });
        watch(value, (current) => {
            if (current !== item.value) {
                item.value = current;
            }
        })
        watch(item, (current) => {
            emit('input', current);
        });
        onMounted(() => {
            item.value = props.value;
            if (props.value && props.value > 0) {
                loading.value = true;
                axios[props.method](`${props.api}/${props.value}`).then(resp => {
                    items.value = resp.data;
                }).catch(err => root.$toast.error(root.$getMsgError(err))).finally(() => (loading.value = false));
            }
            fetch();

        });
        return {
            items,
            search,
            loading,
            item,
        }
    }
}
</script>
