export default [
    {
        path: 'templates',
        props: true,
        component: () => import('/resources/js/components/settings/templates/Index.vue'),
        children: [
            {
                path: '',
                name: 'settings.templates',
                component: () => import('/resources/js/components/settings/templates/List.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'settings.templates.create',
                component: () => import('/resources/js/components/settings/templates/Create.vue'),
            },
            {
                path: ':id',
                props: true,
                component: () => import('/resources/js/components/settings/templates/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'settings.templates.delete',
                        props: true,
                        component: () => import('/resources/js/components/settings/templates/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'settings.templates.show',
                        props: true,
                        component: () => import('/resources/js/components/settings/templates/Show.vue'),
                    },
                    {
                        path: 'update',
                        name: 'settings.templates.update',
                        component: () => import('/resources/js/components/settings/templates/Update.vue'),
                        props: true,
                    },
                ]
            },

        ]

    }
];
