export default [
    {
        path: 'tags',
        component: () => import('../components/Index.vue'),
        props: true,
        children: [
            {
                path: '',
                name: 'tags.list',
                component: () => import('../components/List.vue'),
                props: true,
            },
            {

                path: ':id(\\d+)',
                props: true,
                component: () => import('../components/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'tags.delete',
                        props: true,
                        component: () => import('../components/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'tags.show',
                        props: true,
                        component: () => import('../components/Show.vue'),
                    },

                ]
            },
        ]
    },
];
