export const rules = {
    required: value => !!value || 'Обязательно для заполнения',
    requiredBoolean: value => value === true || value === false || 'Обязательно для заполнения',
    requiredInt: value => /^[\+\-]?\d+$/.test(value) || 'Обязательно для заполнения',
    requiredFloat: value => /^[+-]?\d+(\.\d+)?$/.test(value) || 'Обязательно для заполнения',

    float: value => {
        const pattern = /^[+-]?\d+(\.\d+)?$/
        return pattern.test(value) || 'Нужно указать число'
    },
    number: value => {
        const pattern = /^[\+\-]?\d+$/
        return pattern.test(value) || 'Нужно указать число'
    },
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Некорректный e-mail'
    },
}
export const makeRules = itemRules => {
    if (itemRules && Array.isArray(itemRules)) {
        return itemRules.map(item => rules[item]);
    } else {
        return [];
    }
}