<template>
    <svg :width="width" :height="height" :class="`fill-${variant}`">
        <!--this rect should have rounded corners-->
        <rect x="0" y="0" :width="width" :height="height" :stroke="border_color" :stroke-width="border_width" />
        <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" :font-weight="font_weight"
            :font-size="font_size" :class="`fill-color-${variant} ${text_class}`">{{ textUpper }}</text>
    </svg>
</template>
<script>
import { computed } from 'vue';
export default {
    props: {
        width: {
            type: [Number, String, null],
            default: 48,
        },
        height: {
            type: [Number, String, null],
            default: 48,
        },
        icon: {
            default: 'attach_file',

        },
        text: {
            type: [String, null],
            default: '',
        },
        text_class: {
            type: [String, null],
            default: '',
        },
        font_size: {
            type: [Number, String, null],
            default: '12',
        },
        font_weight: {
            type: [String, null],
            default: 'bold',
        },
        border_width: {
            type: [Number, String, null],
            default: 0,
        },
        border_color: {
            type: [Number, String, null],
            default: 'transparent',
        },
        variant: {
            type: [String, null],
            default: 'info',
        }
    },
    setup(props) {
        const textUpper = computed(() => props.text ? props.text.toUpperCase() : '');
        return {
            textUpper
        };
    }
}
</script>
