import axios from 'axios';

const app = {
    namespaced: true,
    state: () => ({
        drawer: null,
        module: null,
        last_error: null,
        language: null,
        badges: {},
    }),
    mutations: {
        language(state, language) {
            state.language = language;
            localStorage.setItem('app.language', language ? language : '');
        },
        drawer(state, drawer) {
            state.drawer = drawer;
            localStorage.setItem('app.drawer', drawer ? drawer : '');
        },
        last_error(state, last_error) {
            state.last_error = last_error;
        },
        module(state, module) {
            state.module = module;
        },
        badge(state, { slug, value }) {
            state.badges.slug = value;
        },
        badges(state, badges) {
            state.badges = badges;

        },

    },

    actions: {
        menu({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('last_error', null);
                axios.get(`/system/menu`)
                    .then(resp => {
                        commit('drawer', resp.data.items)
                        resolve(state.drawer)
                    })
                    .catch(err => commit('last_error', err))
            })
        },
        badges({ commit, state }) {
            commit('last_error', null);
            axios.get(`/system/badges`)
                .then(resp => {
                    //Пройтись по циклу drawer и заполнить badges
                    commit('badges', resp.data);
                })
                .catch(err => commit('last_error', err))
        },
        badge({ commit, state }, slug) {
            commit('last_error', null);
            axios.get(`/system/badges/${slug}`)
                .then(resp => commit('badge', resp.data.item))
                .catch(err => commit('last_error', err))
        },
    }
};


export default app;
