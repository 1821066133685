import Vue from 'vue'
import VueRouter from 'vue-router'
import leads from './routes/leads'
import content from '../ui/content/routes/index'
import workers from '../ui/workers/routes/index'
import comments from '../ui/comments/routes/index'
import importer from '../ui/importer/routes/index'
import tags from '../ui/tags/routes/index'
import settings from './routes/settings'
import cabinet from './routes/cabinet'
import errors from './routes/errors'
import fundraising from './routes/fundraising'

Vue.use(VueRouter);
cabinet.children.push(
    content,
    ...settings,
    ...leads,
    ...comments,
    ...workers,
    ...tags,
    ...fundraising,
    ...importer,
);
const routes = [
    cabinet,
    ...errors,
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,

})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}


export default router
