import gateways from "./gateways.js";

const settings = {
  path: "settings",
  name: "fundraising.settings",
  component: () =>
    import("/resources/js/components/fundraising/settings/Index.vue"),
  props: true,
  children: [gateways],
};
export default settings;
