<template>
  <div>
    <router-view v-if="isAuthenticated"></router-view>
    <login v-else />
  </div>
</template>
<script>
import { useStore, useRoute } from "@utils";
import login from "./components/Login.vue";
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  onDeactivated,
  getCurrentInstance,
} from "vue";
export default {
  components: {
    login,
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const isAuthenticated = computed(() => {
      return store.state.auth.token ? true : false;
    });
    const onThemeLoad = () => {
      theme.value = localStorage.getItem("theme_mode") == 1 ? true : false;
    };
    const theme = computed({
      get: () => getCurrentInstance().proxy.$vuetify.theme.dark,
      set: (val) => {
        getCurrentInstance().proxy.$vuetify.theme.dark = val;
      },
    });
    onBeforeMount(() => {
      onThemeLoad();
      const authTokenUnsubscribe = store.subscribe((mutation, state) => {
        if (mutation.type === "auth/token") {
        }
      });
      store.dispatch("auth/load");
      axios.interceptors.response.use(undefined, (err) => {
        return new Promise(function (resolve, reject) {
          const { response, config } = err;
          if (
            response &&
            response.status === 401 &&
            config &&
            !config.__isRetryRequest &&
            response &&
            config.url !== "/logout"
          ) {
            store.dispatch("auth/logout");
          }
          throw err;
        });
      });
    });

    onDeactivated(() => {
      authTokenUnsubscribe();
    });

    return {
      isAuthenticated,
    };
  },
};
</script>
