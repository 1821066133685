import _ from "lodash";
window._ = _;
import axios from "axios";
import colors from 'vuetify/lib/util/colors.js';

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = "/api/";
window.axios = axios;
import "./components/share/ckeditor/build/ckeditor";
import "./moment";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VCurrencyField from "v-currency-field"
import Vue from "vue"
import Vuetify from "vuetify";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueToast from "vue-toastification";

import { VueMasonryPlugin } from "vue-masonry";
import VueMasonry from "vue-masonry-css";
import AutocompleteInput from "/resources/js/components/share/inputs/AutocompleteInput.vue";
import AutocompleteApiInput from "/resources/js/components/share/inputs/AutocompleteApiInput.vue";
import AddressInput from "/resources/js/components/share/inputs/Address.vue";
import DateTimeInput from "/resources/js/components/share/inputs/DateTimeInput.vue";
import ImageUpload from "/resources/js/components/share/inputs/ImageUpload.vue";


import FileTypeIcon from "/resources/js/components/share/FileTypeIcon.vue";
import PostEditor from "/resources/js/components/share/PostEditor.vue";

import ru from "vuetify/src/locale/ru";
Vue.component("AutocompleteInput", AutocompleteInput);
Vue.component("AutocompleteApiInput", AutocompleteApiInput);
Vue.use(VueMasonryPlugin);

Vue.component("AddressInput", AddressInput);
Vue.component("ImageUpload", ImageUpload);
Vue.component("FileTypeIcon", FileTypeIcon);
Vue.component("PostEditor", PostEditor);
Vue.component("DateTimeInput", DateTimeInput);
Vue.use(VCurrencyField, {
  locale: "ru-RU",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
Vue.use(VueMasonry);
Vue.use(Vuetify);
Vue.use(VueToast, {
  timeout: 2000,
  position: "bottom-right",
  maxToasts: 6,
  hideProgressBar: true,
});
const dark = {
  accent: '#d00274',
  danger: colors.red.base,
  error: colors.red.base,
  info: colors.teal.base,
  primary: colors.blue.darken2,
  secondary: colors.purple.base,
  success: colors.green.base,
  warning: colors.orange.darken3,
};
const light = {
  accent: '#905',
  danger: colors.red.base,
  error: colors.red.base,
  info: colors.teal.darken1,
  primary: colors.blue.base,
  secondary: colors.purple.lighten1,
  success: colors.green.base,
  warning: colors.orange.base,
};
const opts = {
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    defaultTheme: "dark",

    themes: {
      light, dark
    },
  },
};
const app = new Vue({
  el: "#app",
  router,
  store,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
});
