const campaigns = {
  path: "campaigns",
  name: "fundraising.campaigns",
  component: () =>
    import("/resources/js/components/fundraising/campaigns/Index.vue"),
  props: true,
  children: [
    {
      path: "list",
      name: "fundraising.campaigns.list",
      component: () =>
        import("/resources/js/components/fundraising/campaigns/List.vue"),
      props: true,
    },
    {
      path: ":id",
      props: true,
      component: () =>
        import("/resources/js/components/fundraising/campaigns/Item.vue"),
      children: [
        {
          path: "delete",
          name: "fundraising.campaigns.delete",
          props: true,
          component: () =>
            import("/resources/js/components/fundraising/campaigns/Delete.vue"),
        },
        {
          path: "show",
          name: "fundraising.campaigns.show",
          props: true,
          component: () =>
            import("/resources/js/components/fundraising/campaigns/Show.vue"),
        },
      ],
    },
  ],
};
export default campaigns;
