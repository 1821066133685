export default [
    {
        path: '/importer',
        props: true,
        name: 'importer',
        component: () => import('../components/Index.vue'),
        children: [
            {
                path: ':type',
                name: 'importer.list',
                component: () => import('../components/List.vue'),
                props: true,
            }
        ]

    }
];
