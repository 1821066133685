export default [
    {
        path: '/workers',
        props: true,
        component: () => import('../components/Index.vue'),
        children: [
            {
                path: '',
                name: 'workers',
                component: () => import('../components/List.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'workers.create',
                component: () => import('../components/Create.vue'),
            },
            {
                path: '/workers/:id',
                props: true,
                component: () => import('../components/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'workers.delete',
                        props: true,
                        component: () => import('../components/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'workers.show',
                        props: true,
                        component: () => import('../components/Show.vue'),
                    },
                    {
                        path: 'update',
                        name: 'workers.update',
                        component: () => import('../components/Update.vue'),
                        props: true,
                    },
                    {
                        path: 'update/password',
                        name: 'workers.password',
                        component: () => import('../components/Password.vue'),
                        props: true,
                    },
                ]
            },

        ]

    }
];
