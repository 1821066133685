import moment from "moment";
moment.locale('ru');
export const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

export const datetimeLL = (created, default_format = 'YYYY-MM-DD HH:mm:ss') => {
    return created ? moment(created, default_format).format('ll') : null;
};
export const compareDates = (startDate, endDate = null, default_format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(startDate, default_format).diff(endDate ? moment(endDate, default_format) : moment(new Date())) > 0 ? true : false;
}

export const compareDatesHumanize = (startDate, endDate = null, textUp = '—', textDown = '', default_format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!startDate) return null;
    let mStartDate = moment(startDate, default_format);
    let mEndDate = endDate ? moment(endDate, default_format) : moment(new Date());
    let diffDates = mStartDate.diff(mEndDate);
    return (diffDates > 0 ? textUp : textDown) + moment.duration(mStartDate.diff(mEndDate)).humanize();
}
export const showDateTimeTz = (startDate, default_format = 'YYYY-MM-DD HH:mm:ss', viewFormat = 'DD.MM.YYYY HH:mm') => {
    return !startDate ? null : moment.utc(startDate, default_format).local().format(viewFormat);
}
export const showDateTimeTzSS = (startDate, default_format = 'YYYY-MM-DD HH:mm:ss', viewFormat = 'DD.MM.YYYY HH:mm:ss') => {
    return !startDate ? null : moment.utc(startDate, default_format).local().format(viewFormat);
}

export const showDateTime = (startDate, default_format = 'YYYY-MM-DD HH:mm:ss', viewFormat = 'DD.MM.YYYY HH:mm') => {
    return !startDate ? null : moment(startDate, default_format).local().format(viewFormat);
}
export const showDateTimeSS = (startDate, default_format = 'YYYY-MM-DD HH:mm:ss', viewFormat = 'DD.MM.YYYY HH:mm:ss') => {
    return !startDate ? null : moment(startDate, default_format).local().format(viewFormat);
}
export const showDay = (startDate, default_format = 'YYYY-MM-DD', viewFormat = 'DD') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}
export const showWeekDayLocalize = (startDate, default_format = 'YYYY-MM-DD', viewFormat = 'dddd') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}

export const showMonthLocalize = (startDate, default_format = 'YYYY-MM-DD', viewFormat = 'MMMM') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}
export const showDate = (startDate, default_format = 'YYYY-MM-DD', viewFormat = 'DD.MM.YYYY') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}
export const showTime = (startDate, default_format = ' HH:mm:ss', viewFormat = 'HH:mm:ss') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}
export const nowDate = (default_format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(new Date(), default_format);
}
export const TimeStampDateTime = (startDate, viewFormat = 'DD.MM.YYYY HH:mm:ss') => {
    return !startDate ? null : moment.unix(startDate).format(viewFormat);
}
export const showTimeShort = (startDate, default_format = ' HH:mm:ss', viewFormat = 'HH:mm') => {
    return !startDate ? null : moment(startDate, default_format).format(viewFormat);
}

