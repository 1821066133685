<template>
  <v-row no-gutters>
    <v-col :cols="cols">
      <v-dialog
        ref="date_dialog"
        v-model="dialogDate"
        :close-on-content-click="false"
        :return-value.sync="inputValueDate"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="inputValueDateView"
            :label="`${label} — дата`"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="submitted"
            :dense="dense"
            :append-icon="inputValueDate ? 'close' : null"
            @click:append="inputValueDate = null"
            :class="{ 'mr-2': enableTime }"
            :rules="rules ? rules : []"
          />
        </template>
        <v-date-picker v-model="inputValueDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogDate_close">
            Отменить
          </v-btn>
          <v-btn text color="primary" @click="dialogDate_save"> Выбрать </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col :cols="cols" v-if="enableTime && inputValueDate">
      <v-dialog
        ref="time_dialog"
        v-model="dialogTime"
        :return-value.sync="inputValueTime"
        persistent
        width="290px"
        v-if="enableTime"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="inputValueTimeView"
            :label="`${label} — время`"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="submitted"
            :dense="dense"
            :class="{ 'ml-2': enableTime }"
            :rules="rules ? rules : []"
            :append-icon="inputValueTime ? 'close' : null"
            @click:append="inputValueTime = null"
          />
        </template>
        <v-time-picker
          v-if="dialogTime"
          v-model="inputValueTime"
          full-width
          scrollable
          format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogTime = false">
            Отмена
          </v-btn>
          <v-btn text color="primary" @click="dialogTime_save"> Выбрать </v-btn>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";

export default {
  props: {
    value: {
      default: null,
    },
    label: {
      default: "",
    },
    submitted: {
      default: false,
    },
    viewFormat: {
      default: "DD.MM.YYYY",
    },
    dense: {
      default: false,
    },
    returnFormat: {
      default: "YYYY-MM-DD",
    },
    timeViewFormat: {
      default: "HH:mm:ss",
    },
    timeFormat: {
      default: "HH:mm:ss",
    },
    enableTime: {
      default: false,
    },
    hint: {
      default: "",
    },
    fullFormat: {
      default: "YYYY-MM-DD HH:mm:ss",
    },
    rules: {
      default: [],
    },
  },

  setup(props, { emit }) {
    const dialogDate = ref(false);
    const dialogTime = ref(false);
    const propsValue = ref(props.value);
    const date_dialog = ref(null);
    const time_dialog = ref(null);
    const cols = computed(() =>
      props.enableTime && inputValueDate.value ? 6 : 12
    );
    //date
    const inputValueDate = ref(null);
    const inputValueDateView = computed(() =>
      inputValueDate.value
        ? moment(inputValueDate.value).format(props.viewFormat)
        : null
    );
    const inputValueDateFormatted = computed(() =>
      inputValueDate.value
        ? moment(inputValueDate.value).format(props.returnFormat)
        : null
    );
    //time
    const inputValueTime = ref(null);
    const inputValueTimeView = computed(() =>
      props.enableTime && inputValueTime.value
        ? moment(inputValueTime.value, props.timeFormat).format(
            props.timeViewFormat
          )
        : null
    );
    //result
    const resultFormatted = computed(() => {
      if (props.enableTime) {
        if (inputValueDate.value) {
          if (inputValueTime.value) {
            return inputValueDateFormatted.value + " " + inputValueTime.value;
          } else {
            return inputValueDateFormatted.value + " 00:00:00";
          }
        } else {
          return null;
        }
      } else {
        return inputValueDateFormatted.value;
      }
    });
    const reload = (currentValue) => {
      inputValueDate.value = propsValue.value
        ? moment(currentValue).format(props.returnFormat)
        : null;
      inputValueTime.value = String(
        propsValue.value
          ? moment(currentValue, props.fullFormat).format(props.timeFormat)
          : "00:00:00"
      );
    };
    onMounted(() => {
      reload(propsValue.value);
    });
    watch([inputValueTime, inputValueDate], () => {
      emit("input", resultFormatted.value);
    });

    const dialogDate_close = () => {
      dialogDate.value = false;
    };
    const dialogDate_save = () => {
      date_dialog.value.save(inputValueDate.value);
      // emit('input', resultFormatted);
      dialogDate_close();
    };
    const dialogTime_close = () => {
      dialogTime.value = false;
    };
    const dialogTime_save = () => {
      time_dialog.value.save(inputValueTime.value);
      // emit('input', resultFormatted);
      dialogTime_close();
    };

    return {
      dialogDate,
      dialogDate_close,
      time_dialog,
      date_dialog,
      dialogDate_save,
      cols,
      inputValueDate,
      inputValueDateView,
      inputValueDateFormatted,
      dialogTime,
      dialogTime_close,
      dialogTime_save,
      inputValueTime,
      inputValueTimeView,
    };
  },
};
</script>
