<template>
  <div>
    <p v-if="title">{{ title }}</p>
    <v-row>
      <v-col cols="12">
        <div class="editor-container mb-5" :style="{ height: height }">
          <CKEditor v-model="localValue" :editor="Editor" :config="editorConfig" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  ref,
  watch,
  computed,
  onMounted,
  onDeactivated,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { useToast, useStore, useRoute, getMsgError } from "@utils";
import CKEditor from "@ckeditor/ckeditor5-vue2";
export default {
  components: {
    CKEditor: CKEditor.component,
  },
  props: {
    value: {
      default: "",
    },
    title: {
      default: "",
    },
    submitted: {
      default: false,
    },
    dir: {
      default: null,
    },
    height: {
      default: "200px",
    },
  },

  setup(props, { emit }) {
    const localValue = ref("");
    const toast = useToast();
    const route = useRoute();
    const store = useStore();
    const direction = computed(() => props.dir);
    const propsValue = computed(() => props.value);
    const Editor = computed(() => window.ClassicEditor);
    const editorConfig = ref({
      charset: "utf-8",
      language: {
        ui: "ru",
        content: "ru",
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
            startIndex: false,
          },
        ],
      },
      image: {
        styles: ["alignCenter", "alignLeft", "alignRight"],
        resizeOptions: [
          {
            name: "resizeImage:original",
            label: "Original",
            value: null,
          },
          {
            name: "resizeImage:25",
            label: "25%",
            value: "25",
          },
          {
            name: "resizeImage:50",
            label: "50%",
            value: "50",
          },
          {
            name: "resizeImage:75",
            label: "75%",
            value: "75",
          },
        ],
        toolbar: [
          "imageTextAlternative",
          "toggleImageCaption",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "imageStyle:side",
          "|",
          "resizeImage",
        ],
        insert: {
          integrations: ["insertImageViaUrl"],
        },
      },
      toolbar: {
        shouldNotGroupWhenFull: true,
        withText: true,
        items: [
          "heading",
          "style",
          "sourceEditing",
          "alignment",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "bulletedList",
          "numberedList",
          "|",
          "link",
          "imageInsert",
          "mediaEmbed",
          "blockQuote",
          "removeFormat",
        ],
      },
      htmlEmbed: {
        showPreviews: true,
      },
      simpleUpload: {
        withCredentials: true,
        uploadUrl: `/api/upload/post/${route.params.id}`,
        headers: {
          "X-CSRF-TOKEN": "CSRF-Token",
          Authorization: `Bearer ${store.state.auth.token}`,
        },
      },
    });

    watch(propsValue, (n, p) => {
      if (n && n !== localValue.value) {
        localValue.value = n;
      }
    });
    watch(localValue, (n, p) => {
      if (n && n !== p) {
        if (n !== propsValue.value) {
          emit("input", n);
        }
      } else {
        emit("input", "");
      }
    });

    onMounted(() => {
      localValue.value = props.value ? props.value : "";
    });
    return {
      editorConfig,
      localValue,
      Editor,
    };
  },
};
</script>
