import axios from 'axios';

const badges = {
    namespaced: true,
    state: () => ({
        loading: false,
        page: 1,
        total: null,
        api: null,
        current: null,
        badges: {
            posts: 0,
            comments: 0,
            categories: 0,

        },
    }),
    mutations: {
        page(state, page) {
            state.page = page;
        },
        loading(state, loading) {
            state.loading = loading;
        },
        fetch(state, servicesArr, page) {
            state.items = servicesArr;
            state.page = page;
        },
        total(state, total) {
            state.total = total;
        },
        api(state, api) {
            state.api = api;
        },
        current(state, current) {
            state.current = current;
        },
        badges(state, data) {
            state.badges.comments = data.comments || 0;
            state.badges.questions = data.questions || 0;
            state.badges.tickets = data.tickets || 0;
        },

    },

    actions: {
        badges({ commit }) {

            axios.get(`badges`)
                .then(response => {
                    commit('BADGES', response.data);
                })
                .catch(error => {
                });
        },
    }
};


export default badges;
