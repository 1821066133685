export default [
    {
        path: '/comments',
        props: true,
        component: () => import('../components/Index.vue'),
        children: [
            {
                path: '',
                name: 'comments',
                component: () => import('../components/List.vue'),
                props: true,
            },
            {
                path: '/comments/answer/:id',
                name: 'comments.answer',
                props: true,
                component: () => import('../components/Answer.vue'),
            },
            {
                path: '/comments/create',
                name: 'comments.create',
                props: true,
                component: () => import('../components/Create.vue'),
            },
            {
                path: '/comments/delete/:id',
                name: 'comments.delete',
                props: true,
                component: () => import('../components/Delete.vue'),
            },
            {
                path: '/comments/update/:id',
                name: 'comments.update',
                props: true,
                component: () => import('../components/Update.vue'),
            },

        ]

    }
];
