<template>
  <div>
    <v-hover v-if="localValue && localValue.url">
      <template v-slot:default="{ hover }">
        <v-card class="mx-auto mb-5" elevation="0">
          <v-img class="cursor_pointer" :src="localValue.url" :aspect-ratio="aspectRatio ? aspectRatio : 1" />
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="#036358">
              <v-card-actions>
                <v-btn color="error" class="mx-4" small @click.prevent="onDelete" :disabled="loading">
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-btn color="success" class="mx-4" small @click.prevent="selectFile" :disabled="loading">
                  <v-icon>photo_camera</v-icon>
                </v-btn>
              </v-card-actions>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    <v-file-input v-model="upload" :label="label" :disabled="disabled" v-show="!localValue || !localValue.url"
      :placeholder="placeholder" prepend-icon="photo_camera" :show-size="1000" hide-details :accept="accept" clearable
      ref="pictureInput" class="mb-5" />
  </div>
</template>
<script>
import { getMsgError, getMsgSuccess, useToast } from "@utils";
import { ref, onMounted, watch, computed } from "vue";
export default {
  props: {
    value: {
      default: null,
    },
    disabled: {
      default: null,
    },
    api: {
      default: "picture/model",
    },
    accept: {
      default: "image/jpeg",
    },
    label: {
      default: "Изображение",
    },
    placeholder: {
      default: "Выберите изображение",
    },
    aspectRatio: {
      default: 1,
    },
  },

  setup(props, { emit }) {
    const upload = ref(null);
    const loading = ref(false);
    const localValue = ref({ id: null, url: null, upload: null });
    const blank = ref({ id: null, url: null, upload: null });
    const propsValue = computed(() => props.value);
    const pictureInput = ref(null);
    const toast = useToast();
    const selectFile = () => {
      pictureInput.value.$children[0].$el.click();
    };
    const onDelete = async () => {
      if (localValue.value && localValue.value.id) {
        loading.value = true;
        axios
          .delete(`${props.api}`)
          .then((resp) => {
            loading.value = false;
            upload.value = null;
            Object.assign(localValue.value, blank.value);
            emit("input", localValue.value);
            emit("refresh");
            toast.success("Успешно удалено");
            return false;
          })
          .catch((error) => {
            loading.value = false;
            toast.error(getMsgError(error));
          });
      } else {
        upload.value = null;
        Object.assign(localValue.value, blank.value);
        emit("input", localValue.value);
        toast.success("Успешно очищено");
      }
    };
    watch(
      propsValue,
      (val) => {
        if (val) {
          Object.assign(localValue.value, val);
        } else {
          Object.assign(localValue.value, blank.value);
        }
      },
      {
        deep: true,
      }
    );
    watch(
      localValue,
      (val) => {
        if (val) {
          upload.value = val.upload;
        } else {
          upload.value = null;
        }
      },
      {
        deep: true,
      }
    );
    watch(upload, (val) => {
      if (val) {
        localValue.value.url = URL.createObjectURL(val);
        localValue.value.upload = val;
        emit("input", localValue.value);
      } else {
        Object.assign(localValue.value, props.value);
        emit("input", localValue.value);
      }
    });
    onMounted(() => {
      Object.assign(localValue.value, props.value);
    });
    return {
      upload,
      loading,
      localValue,
      blank,
      pictureInput,
      selectFile,
      onDelete,
      propsValue,
    };
  },
};
</script>
