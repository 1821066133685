import axios from 'axios';

const config = {
    namespaced: true,
    state: () => ({
        site_url: '',
    }),
    getters: {

    },
    mutations: {

        site_url(state, url) {
            state.site_url = url;
        },

    },

    actions: {
        site_url({ commit }) {
            axios.get(`settings/site_url`)
                .then(response => {
                    commit('site_url', response.data.url);
                })
                .catch(error => {
                });
        },
    }
};


export default config;
