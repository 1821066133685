<template>
    <div v-if="items">
        <v-autocomplete
            :multiple="multiple"
            :disabled="disabled"
            :items="items"
            :hint="hint"
            :label="label"
            :dense="dense"
            item-text="title"
            item-value="id"
            :value="Array.isArray(value) ? value.map(item=> typeof item ==='object' && item.hasOwnProperty(valueField) ? item[valueField]: item) : []"
            @change="change"
            @input="change"
            @update="change"
            :rules="rules"
            clearable
        />
    </div>
</template>
<script>
import {computed, onMounted, ref, watch, toRefs} from "vue";

export default {
    props: {
        value: {
            default: null,
        },
        rules:{
            default:undefined,

        },
        api: {
            default: null,
        },
        disabled: {
            default: false,
        },
        hint: {
            default: '',
        },
        label: {
            default: '',
        },
        dense: {
            default: false,
        },
        multiple: {
            default: true,
        },
        valueField: {
            default: 'id',
        },
        textField: {
            default: 'title',
        },
        thumbnailField: {
            default: null,
        }
    },
    setup(props, {root, emit}) {
        const items = ref(null);
        const selected = toRefs(props).value;

        const change = item => {
            emit('input', Array.isArray(item) ? item.map(item => ({id: item})) : []);
        }
        const inputArr = ref([]);
        const calc = itemValue => Array.isArray(itemValue) ? itemValue.map(item => typeof item === 'object' && item.hasOwnProperty(props.valueField) ? item[props.valueField] : item) : [];

        const inputValue = computed(() => calc(props.value))
        const fetch = () => {
            return new Promise((resolve, reject) => {
                if (!props.api)
                    reject({message: 'не задан api'});
                axios.get(props.api).then(resp => {
                    items.value = resp.data;
                    resolve(items.value);
                }).catch(err => reject(err))
            });
        }


        onMounted(() => {
            fetch().then(data => {
                change(inputValue.value)
            });
        });
        return {
            change,
            items,
        }
    }
}
</script>
