import _ from "lodash";
export const fillData = (item) => {
  const data = new FormData();
  const fileFields = ["picture"];
  const pictureFields = ["upload", "attachment"];
  if (typeof item === "object")
    for (let key in item) {
      if (
        key == "watermark" &&
        typeof item.watermark === "object" &&
        item.watermark !== null
      ) {
        if (item.watermark.hasOwnProperty("upload") && item.watermark.upload) {
          data.append("watermark", item.watermark.upload);
        }
      } else if (
        key == "picture" &&
        typeof item.picture === "object" &&
        item.picture !== null
      ) {
        if (item.picture.hasOwnProperty("upload") && item.picture.upload) {
          data.append("picture", item.picture.upload);
        }
      } else if (
        key == "preview" &&
        typeof item.preview === "object" &&
        item.preview !== null
      ) {
        if (item.preview.hasOwnProperty("upload") && item.preview.upload) {
          data.append("preview", item.preview.upload);
        }
      } else if (
        key == "upload" &&
        typeof item.upload === "object" &&
        item.upload !== null
      ) {
        if (item.upload) {
          data.append("upload", item.upload);
        }
      } else if (
        key == "attachment" &&
        typeof item.attachment === "object" &&
        item.attachment !== null
      ) {
        if (item.attachment) {
          data.append("attachment", item.attachment);
        }
      } else if (typeof item[key] === "object" && item[key] !== null) {
        data.append(key, JSON.stringify(item[key]));
      } else if (typeof item[key] === "object" && item[key] === null) {
        data.append(key, ""); //' null
      } else if (typeof item[key] === "boolean") {
        data.append(key, item[key] ? 1 : 0);
      } else if (typeof item[key] === "undefined") {
        //nothing do
      } else {
        data.append(key, item[key]);
      }
    }

  return data;
};

export const fillDataArray = (item) => {
  const data = [];
  if (typeof item === "object")
    for (let key in item) {
      if (item[key]) {
        if (typeof item[key] === "object" && item[key] !== null) {
          data[key] = fillDataArray(item[key]);
        } else if (typeof item[key] === "object" && item[key] === null) {
          data[key] = null;
        } else if (typeof item[key] === "boolean") {
          data[key] = item[key] ? 1 : 0;
        } else if (typeof item[key] === "undefined") {
          //nothing do
        } else {
          data[key] = item[key];
        }
      }
    }
  return data;
};
export const getStatusError = function (error) {
  if (
    typeof error === "object" &&
    error.hasOwnProperty("response") &&
    error.response.hasOwnProperty("status")
  ) {
    return error.response.status;
  } else {
    return null;
  }
};
export const getFieldError = function (error) {
  if (
    typeof error === "object" &&
    error.hasOwnProperty("response") &&
    error.response.hasOwnProperty("data") &&
    error.response.data.hasOwnProperty("field") &&
    error.response.data.field
  ) {
    return error.response.data.field;
  }
  return null;
};
export const  getMsgError =  function (error, $message = "Неизвестная ошибка") {


  if(_.has(error,'response.data.message')){
    return error.response.data.message;
  }
  if(_.has(error,'data.message')){
    return error.data.message;
  }
  if(_.has(error,'message')){
    return error.message;
  }
  return $message;

};
export const getMsgSuccess = function (response, $message = "Успешно") {
  if (
    typeof response === "object" &&
    response.hasOwnProperty("data") &&
    response.data.hasOwnProperty("message")
  ) {
    return response.data.message;
  } else {
    return $message;
  }
};
