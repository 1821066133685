export default {
    path: '/',
    component: () => import('/resources/js/components/Cabinet.vue'),
    children: [
        {
            path: '',
            name: 'Cabinet',
            component: () => import('/resources/js/components/dashboard/Index.vue'),
            props: true,
        },
    ],
}
