<template>
    <div>
        <v-text-field v-if="item" v-model="item.title" prepend-icon="location_on" clearable label="Адрес" type="text"
            @click="dialog = true" @click:prepend="dialog = true" @click:clear="destroy" :disabled="disabled"
            readonly />
        <v-dialog v-model="dialog" persistent hide-overlay :max-width="992">
            <v-card v-if="item">
                <v-toolbar dense flat>
                    <v-card-title><small>{{ title }}</small></v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon  @click="close" class="me-1">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row dense>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="suggestObject" :items="items" :search-input.sync="search"
                                    return-object hide-no-data item-text="value" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.postal_code" :disabled="loading" label="Почтовый индекс" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.country" :disabled="loading" label="Страна" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.federal_district" :disabled="loading"
                                    label="Федеральный округ" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.region" :disabled="loading" label="Регион" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.area" :disabled="loading" label="Район в регионе" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.city" :disabled="loading" label="Город" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.city_area" :disabled="loading"
                                    label="Административный округ (только для Москвы)" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.settlement" :disabled="loading" label="Населенный пункт" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.street" :disabled="loading" label="Улица" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="item.house" :disabled="loading" label="Дом" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.block" :disabled="loading" label="Корпус/строение" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.flat" :disabled="loading" label="Квартира" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.postal_box" :disabled="loading" label="Абонентский ящик" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.geo_lat" :disabled="loading" label="Координаты: широта" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.geo_lon" :disabled="loading" label="Координаты: долгота" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="item.timezone" :disabled="loading" label="Часовой пояс" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="12">
                                <v-textarea v-model="item.content" :disabled="loading"
                                    label="Описание, например: дом, дача, работа" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="close">Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text @click.stop="save">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { computed, onMounted, ref, watch } from "vue";
import { useToast } from "@utils";
import { getMsgError } from '../../../utils';
export default {
    name: 'AddressInput',
    props: {
        disabled: {
            default: false,

        },
        value: {
            default: null,
        },

        apiStore: {
            default: null,
        },
        initItem: null,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const suggestObject = ref(null);
        const dialog = ref(false);
        const item = ref(null);
        const items = ref([]);
        const close = () => dialog.value = false;
        const toast = useToast();
        const search = ref('');
        watch(search, () => {
            if (search.value && search.value.length < 3) return;
            if (loading.value) return;
            loading.value = true;
            axios.post('address/search', {
                search: search.value,
            }).then(resp => {
                items.value = resp.data;
            })
                .catch(err => {
                    items.value = [];
                    toast.error(getMsgError(err))
                }).finally(() => (loading.value = false))
        })
        const destroy = () => {
            return new Promise((resolve, reject) => {
                loading.value = true;
                if (!item.value || !item.value.id) {
                    resolve(null);
                    return;
                }

                axios.delete(`address/${item.value.id}`).then(resp => {
                    loading.value = false;
                    item.value = null;
                    emit('input', null);
                    create();
                    toast.success('Успешно');
                    resolve(resp.data);
                }).catch(err => {
                    item.value = null;
                    loading.value = false;
                    create();
                    reject(err);

                });
            })
        }
        const save = () => {
            if (item.value && item.value.id) {
                update()
            } else {
                destroy().then(() => {
                    store()
                })
            }

        }
        const show = id => {
            return new Promise((resolve, reject) => {
                loading.value = true;
                axios.get(`address/${id}`).then(resp => {
                    loading.value = false;
                    item.value = JSON.parse(JSON.stringify(resp.data));
                    if (resp.data && resp.data.title) {
                        items.value = [suggestObject];
                        search.value = resp.data.title;
                        suggestObject.value = JSON.parse(JSON.stringify(resp.data));
                        suggestObject.value.value = resp.data.title;
                    }
                    resolve(resp.data);
                }).catch(err => {
                    loading.value = false;

                    reject(err);
                });
            })
        }
        const update = () => {
            return new Promise((resolve, reject) => {
                loading.value = true;
                axios.put(`address/${item.value.id}`, {
                    ...item.value
                }).then(resp => {
                    loading.value = false;
                    item.value = JSON.parse(JSON.stringify(resp.data));
                    emit('input', item.value.id);
                    close();
                    toast.success('Успешно');
                    resolve(resp.data);
                }).catch(err => {
                    loading.value = false;
                    toast.error('Не удалось обновить');
                    reject(err);

                });
            })
        }
        const store = () => {
            return new Promise((resolve, reject) => {

                loading.value = true;
                axios.post(`address/${props.apiStore}`, {
                    ...item.value
                }).then(resp => {
                    loading.value = false;
                    item.value = JSON.parse(JSON.stringify(resp.data));
                    emit('input', item.value.id);
                    close();
                    toast.success('Успешно');
                    resolve(resp.data);

                }).catch(err => {
                    loading.value = false;
                    toast.error('Не удалось обновить');
                    reject(err)
                })
            })
        }
        const create = () => {
            return new Promise((resolve, reject) => {
                loading.value = true;
                axios.get(`address/create`).then(resp => {
                    loading.value = false;
                    item.value = JSON.parse(JSON.stringify(resp.data));
                    resolve(resp.data);
                }).catch(error => {
                    loading.value = false;
                    reject(error)
                })
            })
        }
        const title = computed(() => item.value && item.value.id ? 'Изменить' : 'Добавить');

        onMounted(() => {
            if (props.value) {
                show(props.value);
            } else {
                create();
            }

        })

        watch(suggestObject, (currentValue) => {
            if (currentValue && typeof currentValue === 'object' && currentValue.hasOwnProperty('data')) {
                item.value.title = currentValue.value;
                for (let key in item.value) {
                    if (currentValue.data.hasOwnProperty(key)) {
                        item.value[key] = currentValue.data[key];
                    }
                }
            }
        })


        return {
            loading,
            suggestObject,
            item,
            title,
            close,
            update,
            store,
            save,
            destroy,
            dialog, search, items
        }
    }
}
</script>
