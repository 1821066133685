export default [
    {
        path: 'languages',
        props: true,
        component: () => import('/resources/js/components/settings/languages/Index.vue'),
        children: [
            {
                path: '',
                name: 'settings.languages',
                component: () => import('/resources/js/components/settings/languages/List.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'settings.languages.create',
                component: () => import('/resources/js/components/settings/languages/Create.vue'),
            },
            {
                path: ':id',
                props: true,
                component: () => import('/resources/js/components/settings/languages/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'settings.languages.delete',
                        props: true,
                        component: () => import('/resources/js/components/settings/languages/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'settings.languages.show',
                        props: true,
                        component: () => import('/resources/js/components/settings/languages/Show.vue'),
                    },
                    {
                        path: 'update',
                        name: 'settings.languages.update',
                        component: () => import('/resources/js/components/settings/languages/Update.vue'),
                        props: true,
                    },
                ]
            },

        ]

    }
];
