import Vuex, { Store } from "vuex";
import Vue from "vue";
import model from "./modules/model";
import badges from "./modules/badges";
import config from "./modules/config";
import auth from "./modules/auth";
import app from "./modules/app";
import mailing_lists from "./modules/mailing_lists";

Vue.use(Vuex);

const store = new Store({});
store.registerModule("app", app);
store.registerModule("auth", auth);
store.registerModule("config", config);
store.registerModule("badges", badges);

let modelModules = [
  "templates",
  "posts",
  "meta_tags",
  "post_types",
  "text_blocks",
  "text_block_types",
  "json_markups",
  "languages",
  "redirects",
  "workers",
  "leads",
  "menus",
  "notifications",
  "tags",
  "comments",
  //
];
let fundraisingModules = [
  "campaigns",
  "gateways",
  "gateway_points",
  "payments",
  "donors",
  "sources",
  //
];
let importerModules = [
  "importer",
  //
];
modelModules.forEach((val) => {
  store.registerModule(val, model);
  store.commit(`${val}/api`, val);
});
fundraisingModules.forEach((val) => {
  store.registerModule(val, model);
  store.commit(`${val}/api`, `fundraising/` + val);
});
importerModules.forEach((val) => {
  store.registerModule(val, model);
  store.commit(`${val}/api`, `importer/` + val);
});
export default store;
