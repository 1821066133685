import templates from './templates'
import languages from './languages'
import json_markups from './json_markups'
import post_types from './post_types'
import text_block_types from './text_block_types'

export default [

    {
        path: 'system',
        component: () => import('/resources/js/components/settings/Index.vue'),
        name: 'settings',
        children: [
            {
                path: 'menu',
                props: true,
                name: 'settings.menu',
                component: () => import('/resources/js/components/settings/menus/Index.vue'),
            },

            {
                path: 'variables',
                component: () => import('/resources/js/components/settings/Variables.vue'),
                props: true,
                name: 'settings.variables',
            },
            {
                path: 'redirects',
                name: 'settings.redirects',
                props: true,
                component: () => import('/resources/js/components/settings/Redirects.vue'),
            },
            ...languages,
            ...templates,
            ...json_markups,
            ...post_types,
            ...text_block_types,
        ]
    },

]
